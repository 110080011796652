import { computed } from 'vue';
import { formatDate, isHoliday, isWeekday, parseDateSegments } from '@/utils/date';

export const useBusinessHours = () => {
    const startHour = 9;
    const endHour = 17;
    const endMinute = 30;

    const isInsideHours = (hour, minute) => {
        return (hour > startHour && hour < endHour) || hour === startHour || (hour === endHour && minute <= endMinute);
    };

    const isInsideBusinessHours = (date = new Date()) => {
        const { weekday, day, month, hour, minute } = parseDateSegments(date);

        return isInsideHours(hour, minute) && isWeekday(weekday) && !isHoliday(day, month);
    };

    const isBeforeBusinessHours = (date = new Date()) => {
        const { weekday, day, month, hour } = parseDateSegments(date);

        return hour < startHour || (isWeekday(weekday) && !isHoliday(day, month));
    };

    const isAfterBusinessHours = (date = new Date()) => {
        const { weekday, day, month, hour, minute } = parseDateSegments(date);

        return (
            hour > endHour || (hour === endHour && minute > endMinute && isWeekday(weekday) && !isHoliday(day, month))
        );
    };

    // If within working hours, receive reply within 4 hours.
    // Otherwise tomorrow within working hours.
    const receiveReplyBy = computed(() => {
        let now = new Date();
        let inFourHours = new Date(now.setHours(now.getHours() + 4));

        if (isInsideBusinessHours(inFourHours)) {
            return {
                time: formatDate(inFourHours, 'HH:mm'),
                date: 'today',
            };
        }

        let hoursOutside = 0;

        if (inFourHours.getHours() > endHour) {
            hoursOutside = inFourHours.getHours() - endHour;
        } else if (inFourHours.getHours() < startHour) {
            hoursOutside = startHour - inFourHours.getHours();
        }

        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(startHour + hoursOutside);

        if (tomorrow.getDay() === 6 || tomorrow.getDay() === 0) {
            return {
                time: formatDate(tomorrow, 'HH:mm'),
                date: 'next_working_day',
            };
        }

        return {
            time: formatDate(tomorrow, 'HH:mm'),
            date: 'tomorrow',
        };
    });

    return {
        startHour,
        endHour,
        endMinute,
        isInsideBusinessHours,
        isBeforeBusinessHours,
        isAfterBusinessHours,
        receiveReplyBy,
    };
};
