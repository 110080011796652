<script setup>
import { computed, toRefs } from 'vue';
import { useAppStore } from '@/stores/app.js';
import CFErrorInfo from '@/components/CFErrorInfo.vue';
import CFGlobalLoader from '@/components/CFGlobalLoader.vue';
import { useLoader } from '@/stores/loader.js';
import CFMobileNotice from '@/components/CFMobileNotice.vue';

const appStore = useAppStore();
const loader = useLoader();

const { hydrating } = toRefs(appStore);

const error = computed(() => appStore.error);
const isMobile = computed(() => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
});
</script>

<template>
    <div id="cyberfusion">
        <CFMobileNotice v-if="isMobile" />

        <Transition name="fade">
            <CFGlobalLoader v-if="hydrating || loader.loading" />
        </Transition>

        <CFErrorInfo v-if="error" />

        <RouterView
            v-else-if="!hydrating"
            v-slot="{ Component, route }"
        >
            <component
                :is="Component"
                :key="route.path"
            />
        </RouterView>
    </div>
</template>

<style scoped>
:global(#app) {
    height: 100%;
}

#cyberfusion {
    height: 100%;
}
</style>
