import { useUserStore } from '@/stores/user.js';
import dayjs from 'dayjs';
import { i18n } from '@/lang/i18n.js';
import availableLanguages from '@/lang/available-languages.js';

export function setLanguage(lang = null) {
    const usersStore = useUserStore();

    if (!lang) {
        lang = navigator.language.split('-')[0]; // fallback
    }

    if (usersStore.user && usersStore.language) {
        lang = usersStore.language;
    }

    if (!availableLanguages.find((l) => l.locale === lang)) {
        console.warn(`"${lang}" is not an available language.`);
    } else {
        i18n.global.locale.value = lang;
        dayjs.locale(lang);

        document.querySelector('html').setAttribute('lang', lang);
    }
}
