<script setup>
import CFSearchResultRow from '@/components/Search/CFSearchResultRow.vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CFSearchTabs from '@/components/Search/CFSearchTabs.vue';
import CFContentPlaceholders from '@/components/CFContentPlaceholders.vue';
import { useI18n } from 'vue-i18n';
import { useSearch } from '@/composables/search.js';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import SearchIcon from '@/components/Icons/SearchIcon.vue';
import CFCloseButton from '@/components/CFCloseButton.vue';
import { useAppStore } from '@/stores/app.js';
import CFContentPlaceholdersListUser from '@/components/CFContentPlaceholdersListUser.vue';

const emit = defineEmits(['close']);

const appStore = useAppStore();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const inputRef = ref(null);
const { isLoading, searchQuery, searchResults, search } = useSearch();

const searchTabs = [
    { title: t('search.tabs.all'), type: null },
    { title: t('search.tabs.domains'), type: 'domain' },
    { title: t('search.tabs.certificates'), type: 'certificate' },
    { title: t('search.tabs.contacts'), type: 'contact' },
    { title: t('search.tabs.pages'), type: 'page' },
];
const selectedTab = ref(0);

const activeResult = ref(0);

const goToActiveResult = async () => {
    if (isLoading.value) {
        return;
    }

    if (searchResults.value.length > 0 && typeof searchResults.value[activeResult.value] !== 'undefined') {
        onSelect(searchResults.value[activeResult.value].item);
    }
};

const moveActiveResultUp = (event) => {
    if (isLoading.value) {
        return;
    }

    event.preventDefault();
    activeResult.value = activeResult.value - 1 < 0 ? searchResults.value.length - 1 : activeResult.value - 1;
};

const moveActiveResultDown = (event) => {
    if (isLoading.value) {
        return;
    }

    event.preventDefault();
    activeResult.value = activeResult.value + 1 >= searchResults.value.length ? 0 : activeResult.value + 1;
};

const onChangeTab = (index) => {
    activeResult.value = 0;
    selectedTab.value = index;

    if (searchQuery.value) {
        isLoading.value = true;
        search(searchTabs[index].type);
    }
};

const handleTabPress = (event) => {
    if (isLoading.value) {
        return;
    }

    event.preventDefault();

    // Shift + Tab
    if (event.shiftKey) {
        onChangeTab(selectedTab.value - 1 < 0 ? searchTabs.length - 1 : selectedTab.value - 1);
    } else {
        onChangeTab(selectedTab.value + 1 >= searchTabs.length ? 0 : selectedTab.value + 1);
    }
};

const filteredSearchResults = computed(() => {
    if (searchTabs[selectedTab.value].type !== null) {
        return searchResults.value.filter((searchable) => {
            return searchable.item.type === searchTabs[selectedTab.value].type;
        });
    }

    return searchResults.value;
});

const onSelect = (option) => {
    if (option.action) {
        option.action({ close: () => emit('close') });
    } else if (option.to) {
        if (router.currentRoute.value.path === option.to) {
            emit('close');
            return;
        }

        router.push(option.to);
        emit('close');
    } else if (option.href) {
        window.open(option.href, '_blank');
        emit('close');
    }
};

onMounted(async () => {
    inputRef.value.focus();

    searchQuery.value = appStore.searchQuery;

    if (route.query.q) {
        searchQuery.value = route.query.q;
    }
});
</script>

<template>
    <div class="flex max-h-full pr-44">
        <div class="shrink-0 pr-20 pt-3">
            <img
                src="/Cyberfusion_Logo.svg"
                alt="Cyberfusion"
                class="h-[41px] w-auto"
            />
        </div>

        <div class="mt-3 flex flex-auto grow flex-col justify-center">
            <div class="relative">
                <input
                    ref="inputRef"
                    v-model="searchQuery"
                    type="text"
                    name="search"
                    :placeholder="$t('general.search') + '...'"
                    class="block w-full border-0 border-b border-b-primary pl-0 pr-10 text-2xl placeholder:text-neutrals-700 focus:border-b-black focus:ring-0"
                    @keyup.enter="goToActiveResult"
                    @keyup.up="moveActiveResultUp"
                    @keyup.down="moveActiveResultDown"
                    @keydown.tab="handleTabPress"
                />

                <button
                    v-if="searchQuery !== '' && searchQuery !== null"
                    type="button"
                    class="absolute inset-y-0 right-10 flex cursor-pointer items-center px-1"
                    @click="searchQuery = ''"
                >
                    <XMarkIcon class="h-5 w-5" />
                </button>

                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <SearchIcon class="h-6 w-6" />
                </div>
            </div>

            <CFSearchTabs
                :tabs="searchTabs"
                :selected-tab="selectedTab"
                @change-tab="onChangeTab"
            />

            <template v-if="!isLoading">
                <div
                    v-if="filteredSearchResults.length > 0"
                    class="mt-8 w-full divide-y divide-gray-200 overflow-y-auto lg:pr-4 xl:max-w-3xl xl:pr-6 2xl:max-w-5xl"
                >
                    <CFSearchResultRow
                        v-for="(result, index) in filteredSearchResults"
                        :key="result.name"
                        :result="result"
                        :is-active="activeResult === index"
                        :aria-selected="activeResult === index"
                        :query="searchQuery"
                        @mouseover="activeResult = index"
                        @select="onSelect"
                    />
                </div>

                <p
                    v-else-if="searchQuery !== ''"
                    class="py-8"
                >
                    {{ $t('search.no_results') }}
                </p>
            </template>

            <CFContentPlaceholders
                v-else
                class="mt-8 w-full lg:pr-4 xl:max-w-3xl xl:pr-6 2xl:max-w-5xl"
            >
                <CFContentPlaceholdersListUser :count="1" />
            </CFContentPlaceholders>
        </div>

        <CFCloseButton
            class="absolute right-8 top-9"
            @click="emit('close')"
        />
    </div>
</template>
