<script setup>
import CFContentPlaceholdersList from '@/components/CFContentPlaceholdersList.vue';
import CFIntersectionTrigger from '@/components/CFIntersectionTrigger.vue';
import CFContentPlaceholders from '@/components/CFContentPlaceholders.vue';
import CFSearchInput from '@/components/CFSearchInput.vue';
import { onMounted, ref, shallowRef } from 'vue';
import { watchDebounced } from '@vueuse/core';
import api from '@/plugins/axios.js';

const props = defineProps({
    route: {
        type: String,
        required: true,
    },
    searchPlaceholder: {
        type: String,
        required: true,
    },
});

const isInitialLoading = ref(true);
const isLoading = ref(true);
const query = ref('');
const selectedItem = shallowRef(null);
const items = ref([]);

const page = ref(1);
const hasMoreItems = ref(true);

const getItems = async (params) => {
    isLoading.value = true;

    try {
        const response = await api.get(props.route, { params });

        items.value = [...items.value, ...response.data.data];

        if (response.data.meta.current_page === response.data.meta.last_page) {
            hasMoreItems.value = false;
        }
    } catch (error) {
        // prevents the intersection trigger from firing again
        hasMoreItems.value = false;
    } finally {
        isLoading.value = false;
    }
};

const loadMoreItems = () => {
    if (!hasMoreItems.value || isLoading.value) {
        return;
    }

    let params = {
        page: ++page.value,
        perPage: 25,
    };

    if (query.value) {
        params.filter = {
            search: query.value,
        };
    }

    getItems(params);
};

watchDebounced(
    query,
    () => {
        selectedItem.value = null;
        items.value = [];
        page.value = 1;
        hasMoreItems.value = true;
        loadMoreItems();
    },
    { debounce: 500, maxWait: 1000 }
);

onMounted(async () => {
    await getItems();
    isInitialLoading.value = false;
});
</script>

<template>
    <div class="flex h-80 flex-col">
        <div class="bg-white px-1 py-2">
            <CFSearchInput
                v-model="query"
                :placeholder="searchPlaceholder"
            />
        </div>

        <div class="-mb-5 grow basis-0 overflow-y-auto overflow-x-hidden border-t border-black bg-white px-4">
            <template v-if="!isInitialLoading">
                <p
                    v-if="items.length === 0 && !isLoading"
                    class="py-4 text-center"
                >
                    {{ $t('form.errors.no_results') }}
                </p>

                <template
                    v-for="item in items"
                    :key="item.id"
                >
                    <slot
                        name="item"
                        :item="item"
                        :selected-item="selectedItem"
                        :on-select="() => (selectedItem = item)"
                    />
                </template>

                <CFIntersectionTrigger @intersected="loadMoreItems" />

                <CFContentPlaceholders v-if="isLoading">
                    <CFContentPlaceholdersList :count="1" />
                </CFContentPlaceholders>
            </template>

            <CFContentPlaceholders v-else>
                <CFContentPlaceholdersList :count="2" />
            </CFContentPlaceholders>
        </div>
    </div>
</template>
