<script setup>
import { ref } from 'vue';
import { useUserStore } from '@/stores/user.js';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { unexpectedError } from '@/utils/unexpected-error.js';

const userStore = useUserStore();
const toast = useToast();
const { t } = useI18n();

const showVerifyButton = ref(true);

const resendVerifyEmail = async () => {
    if (userStore.isVerified) {
        toast.error(t('auth.email_already_verified'));

        return;
    }

    showVerifyButton.value = false;

    try {
        await userStore.resendVerifyEmail();

        toast.success(t('auth.verification_email_sent'));
    } catch (error) {
        showVerifyButton.value = true;

        unexpectedError(error);
    }
};
</script>

<template>
    <div
        v-if="!userStore.isVerified || userStore.hasPendingChange"
        class="mb-8 mr-48 flex flex-wrap items-center justify-between gap-4 border border-warning bg-transparent px-6 py-4 text-warning xl:flex-nowrap"
    >
        <div class="grow font-semibold">
            {{ $t('auth.email_not_verified') }}
        </div>

        <button
            v-if="showVerifyButton"
            class="shrink-0 cursor-pointer bg-warning px-3 py-2 text-sm font-semibold text-white hover:bg-warning/80"
            @click="resendVerifyEmail"
        >
            {{ $t('auth.resend_verification_email') }}
        </button>
    </div>
</template>
