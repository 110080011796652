<script setup>
import CFTicketModalInner from '@/components/Ticket/CFTicketModalInner.vue';
import CFButton from '@/components/CFButton.vue';
import { useTicketStore } from '@/stores/ticket.js';
import CreateTicketStep from '@/components/Ticket/Steps/CreateTicketStep.vue';
import ProductSelectStep from '@/components/Ticket/Steps/ProductSelectStep.vue';
import CFCircleBackButton from '@/components/CFCircleBackButton.vue';
import SelectSSLStep from '@/components/Ticket/Steps/SelectSSLStep.vue';
import CFTicketSelectProductsWrapper from '@/components/Ticket/CFTicketSelectProductsWrapper.vue';
import CFTicketSSLItem from '@/components/Ticket/CFTicketSSLItem.vue';
import { shallowRef } from 'vue';

const ticketStore = useTicketStore();

const selectedCertificate = shallowRef(null);

const setSelectedCertificate = (certificate, callback) => {
    selectedCertificate.value = certificate;
    callback(certificate);
};

const goToCreateTicketStep = () => {
    ticketStore.setComponent(CreateTicketStep, SelectSSLStep, {
        ssl: selectedCertificate,
    });
};
</script>

<template>
    <CFTicketModalInner :title="$t('support.select_product')">
        <CFTicketSelectProductsWrapper
            :search-placeholder="$t('general.search_for_ssl_certificate')"
            route="general/ssl-certificates"
        >
            <template #item="{ item, selectedItem, onSelect }">
                <CFTicketSSLItem
                    :certificate="item"
                    :selected-certificate="selectedItem"
                    @select="setSelectedCertificate(item, onSelect)"
                />
            </template>
        </CFTicketSelectProductsWrapper>

        <template #actions>
            <CFCircleBackButton @click="ticketStore.goToPrevious(ProductSelectStep)">
                {{ $t('button.back') }}
            </CFCircleBackButton>

            <CFButton
                type="button"
                :disabled="!selectedCertificate"
                @click="goToCreateTicketStep"
            >
                {{ $t('button.next') }}
            </CFButton>
        </template>
    </CFTicketModalInner>
</template>
