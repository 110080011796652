<script setup>
import { computed } from 'vue';
import api from '@/plugins/axios.js';
import dayjs from 'dayjs';
import { unexpectedError } from '@/utils/unexpected-error.js';

const props = defineProps({
    notification: {
        type: Object,
        required: true,
    },
    color: {
        type: String,
        default: 'primary',
    },
    selected: {
        type: Object,
        default: null,
    },
});

const emits = defineEmits(['viewNotification']);

const notificationClass = computed(() => {
    return {
        'unread': !props.notification.attributes.read_at,
        'warning': props.notification.attributes.type === 'warning',
        'border-b-primary': props.color === 'primary',
        'border-b-secondary': props.color === 'secondary',
    };
});

const showNotification = () => {
    if (!props.notification.attributes.read_at) {
        markAsRead(props.notification.id);
    }

    emits('viewNotification', props.notification);
};

const markAsRead = async (notificationId) => {
    try {
        await api.post(`/general/notifications/${notificationId}/read`);
    } catch (e) {
        unexpectedError(e);
    }
};

const relativeDate = computed(() => dayjs().to(dayjs(props.notification.attributes.notified_at)));
</script>

<template>
    <div
        :class="[
            notificationClass,
            {
                'notification-active': selected?.id === notification.id,
            },
        ]"
        class="btn-notification"
        @click="showNotification"
    >
        <div class="btn-notification--container">
            <div class="relative z-10 flex h-full flex-col">
                <p>
                    {{ notification.attributes.subject }}
                </p>
                <div class="mt-1">
                    <span
                        v-tooltip="{
                            theme: 'light-tooltip',
                            content: dayjs(props.notification.attributes.notified_at).format('DD-MM-YYYY HH:mm'),
                        }"
                        class="text-xs hover:underline hover:underline-offset-3"
                    >
                        {{ relativeDate }}
                    </span>
                </div>
                <button
                    class="btn-notification--view"
                    type="button"
                >
                    <span class="text-sm leading-none">{{ $t('general.view_notification') }}</span>
                    <svg
                        :class="{ '-rotate-45': selected?.id !== notification.id }"
                        class="ml-2"
                        height="18"
                        viewBox="-0.5 -0.5 24 24"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.9583333333333334 11.498083333333334a10.541666666666668 10.541666666666668 0 1 0 21.083333333333336 0 10.541666666666668 10.541666666666668 0 1 0 -21.083333333333336 0Z"
                            fill="currentColor"
                            stroke="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                        ></path>
                        <path
                            :stroke="color === 'secondary' && selected?.id !== notification.id ? 'black' : 'white'"
                            d="m5.75 11.498083333333334 12.458333333333334 0"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                        ></path>
                        <path
                            :stroke="color === 'secondary' && selected?.id !== notification.id ? 'black' : 'white'"
                            d="m13.895833333333334 7.185583333333334 4.3125 4.3125 -4.3125 4.3125"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
        <div
            v-if="!notification.attributes.read_at"
            class="btn-notification--alert"
        ></div>
    </div>
</template>

<style scoped>
.btn-notification {
    @apply relative flex h-[140px] w-full cursor-pointer flex-col justify-start border-b;

    &:only-child {
        @apply max-w-[calc(50%_-_0.5rem)];
    }

    svg {
        @apply fill-primary stroke-primary;

        path {
            @apply stroke-white;
        }
    }

    p {
        @apply line-clamp-3 break-words text-left text-lg leading-4.5;
    }

    &--container {
        @apply relative flex h-[140px] w-full flex-col justify-start overflow-hidden pr-6 pt-4 transition-all duration-200 ease-in-out before:absolute before:left-0 before:top-0 before:z-0 before:h-full before:w-full before:translate-y-full before:transition-all before:duration-300 before:ease-in-out last:mb-0;
    }

    &--view {
        @apply mb-5 mt-auto flex items-end transition-all duration-75 ease-in-out;

        svg {
            @apply fill-current;
        }
    }

    &--alert {
        @apply absolute -left-4 top-[19px] h-2 w-2 rounded-full bg-gradient-to-b from-[#FF5300] to-[#E884E4];
    }

    &:hover,
    &:focus {
        .btn-notification--container {
            @apply px-3 text-secondary before:translate-y-0 before:bg-primary;
        }

        svg {
            @apply rotate-0 fill-secondary stroke-primary;

            path {
                @apply stroke-primary;
            }
        }
    }
}

#notifications-sidebar {
    .btn-notification {
        @apply border-0 px-8;

        &:only-child {
            @apply max-w-full;
        }

        &--alert {
            @apply left-3;
        }

        &--container {
            @apply border-b;
        }

        &:hover,
        &:focus {
            .btn-notification--container {
                @apply text-primary before:bg-white;
            }

            svg {
                path {
                    @apply stroke-secondary;
                }
            }
        }

        svg {
            path {
                @apply stroke-primary;
            }
        }
    }

    .btn-notification.notification-active {
        @apply relative text-primary before:absolute before:-top-px before:left-8 before:z-0 before:h-[calc(100%_+_1px)] before:w-[calc(100%_-_2.5rem)] before:bg-neutrals-300;

        .btn-notification--container {
            @apply px-3;
        }

        &:hover,
        &:focus {
            @apply text-primary;

            .btn-notification--container {
                @apply before:bg-neutrals-300;
            }
        }

        svg {
            path {
                @apply stroke-white;
            }
        }
    }
}
</style>
