import { defineStore } from 'pinia';
import i18n from '@/lang/i18n';

const { global } = i18n;

export const useDialogStore = defineStore('dialog', {
    state: () => ({
        active: false,
        title: '',
        message: '',
        size: 'md',
        data: null,
        variant: 'primary', // primary || accent
        yesLabel: global.t('general.confirm'),
        noLabel: global.t('button.cancel'),
        resolve: null,
        hideNoButton: false,
        processing: false,
    }),

    actions: {
        openDialog(data) {
            Object.keys(data).forEach((key) => {
                if (data[key]) {
                    this[key] = data[key];
                }
            });
            this.active = true;

            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        },

        closeDialog() {
            this.active = false;

            setTimeout(() => {
                this.title = '';
                this.message = '';
                this.data = null;
                this.processing = false;
            }, 300);
        },
    },
});
