<script setup>
import { computed } from 'vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useDialogStore } from '@/stores/dialog';
import CFButton from '@/components/CFButton.vue';
import CFCircleCloseButton from '@/components/CFCircleCloseButton.vue';

const dialogStore = useDialogStore();

function resolveDialog(resValue) {
    if (resValue) {
        dialogStore.processing = true;
    }

    dialogStore.resolve(resValue);
    dialogStore.closeDialog();
}

const dialogSizeClasses = computed(() => {
    const size = dialogStore.size;

    switch (size) {
        case 'sm':
            return 'sm:max-w-sm';
        case 'md':
            return 'sm:max-w-md';
        case 'lg':
            return 'sm:max-w-lg';

        default:
            return 'sm:max-w-md';
    }
});
</script>

<template>
    <TransitionRoot
        as="template"
        :show="dialogStore.active"
    >
        <Dialog
            as="div"
            static
            class="fixed inset-0 z-20 overflow-y-auto"
            :open="dialogStore.active"
            @close="resolveDialog(false)"
        >
            <div class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-primary bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
                <span
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="relative my-8 inline-block w-full overflow-hidden bg-grey-light px-7 pb-6 pt-7 text-left align-middle shadow-xl transition-all"
                        :class="dialogSizeClasses"
                    >
                        <div>
                            <div class="mb-4">
                                <DialogTitle
                                    as="h3"
                                    class="text-base font-bold text-black"
                                >
                                    {{ dialogStore.title }}
                                </DialogTitle>
                                <CFCircleCloseButton
                                    class="absolute right-7 top-5"
                                    @click="resolveDialog(false)"
                                />
                            </div>
                            <p class="text-sm text-gray-500">
                                {{ dialogStore.message }}
                            </p>
                        </div>

                        <div
                            class="mt-6 flex flex-row-reverse gap-3 border-t border-black pt-5"
                            :class="dialogStore.hideNoButton ? 'justify-end' : 'justify-between'"
                        >
                            <CFButton
                                :color="dialogStore.variant"
                                :loading="dialogStore.processing"
                                @click="resolveDialog(true)"
                            >
                                {{ dialogStore.yesLabel }}
                            </CFButton>

                            <CFButton
                                v-if="!dialogStore.hideNoButton"
                                outline
                                @click="resolveDialog(false)"
                            >
                                {{ dialogStore.noLabel }}
                            </CFButton>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
