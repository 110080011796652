<script setup>
import { ArrowUturnLeftIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import TrashIcon from '@/components/Icons/TrashIcon.vue';
import SettingsIcon from '@/components/Icons/SettingsIcon.vue';

const props = defineProps({
    color: {
        type: String,
        default: 'primary',
    },
    outline: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'medium',
        validator: (v) => ['xs', 'small', 'medium', 'large', 'full', 'auth'].includes(v),
    },
    icon: {
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: 'submit',
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const loaderSize = computed(() => {
    return {
        'w-3 h-3': props.size === 'xs' || props.size === 'small',
        'w-5 h-5 -my-1': props.size === 'medium' || props.size === 'large' || props.size === 'full',
        'w-8 h-8': props.size === 'auth',
    };
});
</script>

<template>
    <button
        :type="type"
        :class="{
            'border-primary bg-primary text-secondary hover:bg-transparent hover:text-primary':
                color === 'primary' && !outline,
            'border-primary bg-secondary text-primary hover:bg-primary hover:text-secondary':
                color === 'secondary' && !outline,
            'border-accent bg-accent text-secondary': color === 'accent' && !outline,
            'border border-primary bg-transparent text-primary hover:bg-primary hover:text-secondary':
                color === 'primary' && outline,
            'border-primary hover:bg-primary hover:text-secondary': color === 'outline',
            'border border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-primary':
                color === 'secondary' && outline,
            'border border-warning bg-transparent text-warning hover:bg-warning hover:text-white':
                color === 'warning' && outline,
            'opacity-10': disabled,
            'px-2.5 py-2 text-xs leading-3 -tracking-[0.12px]': size === 'small',
            'px-4 py-2 -tracking-[0.14px]': size === 'medium',
            'min-h-[52px] px-4.5 py-4 text-base leading-4.5 -tracking-[0.16px]': size === 'large',
            'block w-full py-2': size === 'full',
            'block h-[70px] w-full rounded-[10px] px-[25px] text-[32px] leading-8': size === 'auth',
            'cursor-pointer': !disabled && !loading,
            'pointer-events-none [&>:not([data-loading-indicator])]:opacity-0 [&>[data-loading-indicator]]:opacity-100':
                disabled || loading,
        }"
        :disabled="disabled || loading"
        class="relative flex shrink-0 items-center justify-center rounded-[5px] border text-center transition duration-200 ease-in-out"
    >
        <span
            v-if="loading"
            class="absolute inset-0 flex items-center justify-center opacity-0"
            role="status"
            data-loading-indicator
        >
            <svg
                class="shrink-0 animate-spin"
                :class="loaderSize"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                aria-hidden="true"
                data-slot="icon"
            >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                ></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
            <span class="sr-only">{{ $t('general.loading') }}...</span>
        </span>

        <i
            v-if="icon === 'next-arrow'"
            :class="[size === 'auth' ? 'ml-auto w-8' : 'ml-2']"
        />
        <SettingsIcon
            v-else-if="icon === 'pencil'"
            class="h-4 w-4"
        />
        <ArrowUturnLeftIcon
            v-else-if="icon === 'revert'"
            class="mr-1.5 h-4 w-4"
        />
        <svg
            v-else-if="icon === 'plus'"
            class="-mt-0.5 mr-1.5 fill-current"
            viewBox="-0.75 -0.75 24 24"
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            width="18"
        >
            <path
                d="m11.25 7.03125 0 8.4375"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            ></path>
            <path
                d="m7.03125 11.25 8.4375 0"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            ></path>
            <path
                d="M0.703125 11.25a10.546875 10.546875 0 1 0 21.09375 0 10.546875 10.546875 0 1 0 -21.09375 0Z"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            ></path>
        </svg>
        <svg
            v-if="icon === 'back'"
            class="mr-1.5 -rotate-90"
            viewBox="-0.5 -0.5 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
        >
            <path
                d="m5.270833333333334 22.519875 0 -17.25 17.25 0"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
            ></path>
            <path
                d="m17.729166666666668 0.47820833333333335 4.791666666666667 4.791666666666667 -4.791666666666667 4.791666666666667"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
            ></path>
        </svg>
        <TrashIcon
            v-if="icon === 'trash'"
            class="h-4 w-4"
            :class="{
                'mr-2.5': $slots.default,
            }"
        />
        <CheckIcon
            v-if="icon === 'check'"
            class="-mt-0.5 mr-1.5 h-3.5 w-3.5"
        />

        <span v-if="loading">
            <slot />
        </span>
        <template v-else>
            <slot />
        </template>

        <svg
            v-if="icon === 'next-arrow'"
            class="fill-current stroke-primary"
            :class="[size === 'auth' ? 'ml-auto mr-2.5 w-8' : '-mr-1 ml-2']"
            viewBox="-0.5 -0.5 24 24"
            xmlns="http://www.w3.org/2000/svg"
            :height="size === 'auth' ? 32 : 20"
            :width="size === 'auth' ? 32 : 20"
        >
            <path
                d="M0.9583333333333334 11.498083333333334a10.541666666666668 10.541666666666668 0 1 0 21.083333333333336 0 10.541666666666668 10.541666666666668 0 1 0 -21.083333333333336 0Z"
                class="fill-secondary"
                stroke="stroke-primary"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="m5.75 11.498083333333334 12.458333333333334 0"
                fill="none"
                stroke="stroke-primary"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="m13.895833333333334 7.185583333333334 4.3125 4.3125 -4.3125 4.3125"
                fill="none"
                stroke="stroke-primary"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
        </svg>
    </button>
</template>

<style scoped>
button.primary-alt {
    svg:not(.animate-spin) {
        @apply rounded-full border-0 bg-primary ring-1 ring-transparent;

        path {
            @apply stroke-secondary;

            &:first-child {
                @apply fill-primary stroke-primary;
            }
        }
    }

    &:hover {
        svg {
            @apply ring-secondary;

            path:first-child {
                @apply stroke-primary;
            }
        }
    }
}
</style>
