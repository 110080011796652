<script setup>
import { useNotificationsStore } from '@/stores/notifications';
import BellIcon from '@/components/Icons/BellIcon.vue';
import { Tooltip as VueTooltip } from 'floating-vue';
import { defineShortcuts } from '@/composables/defineShortcuts.js';
import CFKbd from '@/components/CFKbd.vue';

const notificationStore = useNotificationsStore();

defineShortcuts({
    shift_n: {
        handler: () => {
            if (notificationStore.isOpen) {
                notificationStore.close();
            } else {
                notificationStore.open();
            }
        },
    },
});
</script>

<template>
    <VueTooltip theme="dark-tooltip">
        <button
            type="button"
            class="btn btn-rounded-full border-primary text-secondary before:bg-primary after:bg-secondary hover:text-primary"
            @click="notificationStore.open()"
        >
            <span
                v-if="notificationStore.hasUnreadNotifications"
                class="bg-pink-gradient absolute -right-1 top-0 h-4 w-4 rounded-full"
            ></span>
            <BellIcon />
        </button>

        <template #popper>
            <div class="flex flex-col items-center">
                <strong>{{ $t('shortcuts.notifications') }}</strong>
                <CFKbd
                    value="Shift + N"
                    class="bg-primary text-neutrals-600 ring-primary"
                />
            </div>
        </template>
    </VueTooltip>
</template>
