<script setup>
import { XCircleIcon } from '@heroicons/vue/24/outline';

import { computed } from 'vue';

const props = defineProps({
    color: {
        type: String,
        default: 'primary',
        validator: (v) => ['primary', 'accent', 'success', 'warning', 'error'].includes(v),
    },
    icon: {
        type: [Object, Function],
        default: null,
    },
    dismissible: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close']);

const backgroundClass = computed(() => {
    return {
        'border-primary text-primary': props.color === 'primary',
        'border-accent text-accent': props.color === 'accent' || props.color === 'pending',
        'border-lime-500 text-white': props.color === 'success' || props.color === 'active',
        'border-warning text-warning': props.color === 'warning',
    };
});
</script>

<template>
    <div
        class="flex items-center gap-3 border bg-transparent p-3"
        :class="backgroundClass"
    >
        <div
            v-if="icon"
            class="shrink-0"
        >
            <component
                :is="icon"
                class="size-6"
            />
        </div>
        <div class="grow">
            <p class="text-sm">
                <slot />
            </p>
        </div>
        <div
            v-if="dismissible"
            class="ml-auto shrink-0"
        >
            <div class="-m-1.5">
                <button
                    type="button"
                    class="inline-flex p-1.5"
                    @click="emit('close')"
                >
                    <span class="sr-only">{{ $t('button.close') }}</span>
                    <XCircleIcon
                        class="size-5"
                        aria-hidden="true"
                    />
                </button>
            </div>
        </div>
    </div>
</template>
