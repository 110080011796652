<script setup>
import CFTicketModalInner from '@/components/Ticket/CFTicketModalInner.vue';
import CFButton from '@/components/CFButton.vue';
import { useTicketStore } from '@/stores/ticket.js';
import { shallowRef } from 'vue';
import CreateTicketStep from '@/components/Ticket/Steps/CreateTicketStep.vue';
import SelectDomainStep from '@/components/Ticket/Steps/SelectDomainStep.vue';
import ProductSelectStep from '@/components/Ticket/Steps/ProductSelectStep.vue';
import CFCircleBackButton from '@/components/CFCircleBackButton.vue';
import CFTicketDomainItem from '@/components/Ticket/CFTicketDomainItem.vue';
import CFTicketSelectProductsWrapper from '@/components/Ticket/CFTicketSelectProductsWrapper.vue';

const ticketStore = useTicketStore();

const selectedDomain = shallowRef(null);

const setSelectedDomain = (domain, callback) => {
    selectedDomain.value = domain;
    callback(domain);
};

const goToCreateTicketStep = () => {
    ticketStore.setComponent(CreateTicketStep, SelectDomainStep, {
        domain: selectedDomain,
    });
};
</script>

<template>
    <CFTicketModalInner :title="$t('support.select_product')">
        <CFTicketSelectProductsWrapper
            :search-placeholder="$t('general.search_for_domain')"
            route="general/domains"
        >
            <template #item="{ item, selectedItem, onSelect }">
                <CFTicketDomainItem
                    :domain="item"
                    :selected-domain="selectedItem"
                    @select="setSelectedDomain(item, onSelect)"
                />
            </template>
        </CFTicketSelectProductsWrapper>

        <template #actions>
            <CFCircleBackButton @click="ticketStore.goToPrevious(ProductSelectStep)">
                {{ $t('button.back') }}
            </CFCircleBackButton>

            <CFButton
                type="button"
                :disabled="!selectedDomain"
                @click="goToCreateTicketStep"
            >
                {{ $t('button.next') }}
            </CFButton>
        </template>
    </CFTicketModalInner>
</template>
