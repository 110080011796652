import { defineStore } from 'pinia';
import api from '@/plugins/axios.js';
import { reactive, ref } from 'vue';

export const useDomainsStore = defineStore('domains', () => {
    const state = reactive({
        page: 1,
        sort: '-status',
        status: [],
    });
    const search = ref('');
    const columnVisibility = ref(['status']);

    const setPage = (newPage) => {
        state.page = newPage;
    };

    const setSearch = (newSearch) => {
        search.value = newSearch;
        setPage(1);
    };

    const setFilters = (newFilters) => {
        Object.keys(newFilters).forEach((key) => {
            if (newFilters[key] === undefined) {
                return;
            }

            state[key] = newFilters[key];
        });
        setPage(1);
    };

    const setSort = (newSort) => {
        state.sort = newSort;
    };

    const setColumnVisibility = (columns) => {
        columnVisibility.value = columns;
    };

    const resetFilters = () => {
        state.page = 1;
        state.status = [];
        search.value = '';
    };

    const getDomainsWithFilters = async () => {
        const params = {
            page: state.page,
            sort: state.sort,
            filter: {},
        };

        if (search.value) {
            params['filter']['search'] = search.value;
        }

        Object.keys(state).forEach((key) => {
            if (key === 'page' || key === 'sort') {
                return;
            }

            if (Array.isArray(state[key]) && state[key].length) {
                params['filter'][key] = state[key].join('|');
            } else if (state[key]) {
                params['filter'][key] = state[key];
            }
        });

        const { data } = await getDomains(params);

        return data;
    };

    async function hydrate() {
        await getDomains();
    }

    function checkDomainAvailable(domain) {
        return api.get(`/general/domains/check/${domain}`);
    }

    function parseDomain(domain) {
        return api.get(`/general/domains/parse/${domain}`);
    }

    function getPopularTlds() {
        return api.get('/general/domains/tlds');
    }

    function getDomains(params = {}) {
        return api.get(`/general/domains`, { params });
    }

    function getDomain(domain) {
        return api.get(`/general/domains/${domain}`);
    }

    function orderDomain(data, action) {
        return api.post(`/general/domains/${action}`, data);
    }

    function updateDomain(domain, data) {
        return api.patch(`/general/domains/${domain}`, data);
    }

    function cancelDomain(domain) {
        return api.delete(`/general/domains/${domain}`);
    }

    function restoreDomain(domain) {
        return api.post(`general/domains/${domain}/recover`);
    }

    function retryTransfer(domain, data) {
        return api.post(`general/domains/${domain}/retry-transfer`, data);
    }

    function registerDomain(data) {
        return api.post(`/general/domains/register`, data);
    }

    function toggleDomainLock(domain, method) {
        return api({
            method,
            url: `/general/domains/${domain}/lock`,
        });
    }

    function getDomainEpp(domain) {
        return api.get(`/general/domains/${domain}/epp`);
    }

    function setDomainNameservers(domain, data) {
        return api.put(`/general/domains/${domain}/nameservers`, {
            nameservers: data,
        });
    }

    function checkTldRequirements(data) {
        return api.post(`/general/domains/tld-requirements`, data);
    }

    return {
        state,
        search,
        columnVisibility,
        setPage,
        setSearch,
        setFilters,
        setSort,
        setColumnVisibility,
        resetFilters,
        getDomainsWithFilters,
        hydrate,
        checkDomainAvailable,
        parseDomain,
        getPopularTlds,
        getDomains,
        getDomain,
        orderDomain,
        updateDomain,
        cancelDomain,
        restoreDomain,
        retryTransfer,
        registerDomain,
        toggleDomainLock,
        getDomainEpp,
        setDomainNameservers,
        checkTldRequirements,
    };
});
