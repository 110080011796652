import { useToast } from 'vue-toastification';
import { translateAPIError } from '@/lang/translate-api-error.js';

let toast;

export function unexpectedError(error, fallback = undefined) {
    if (!toast) {
        toast = useToast();
    }

    const code = translateAPIError(error, fallback);

    console.warn(error);

    toast.error(code);
}
