import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useLocalStorage, useStorage } from '@vueuse/core';

export const useAppStore = defineStore('app', () => {
    const hydrated = ref(false); // This indicates that the app is ready to be rendered
    const hydrating = ref(false);
    const error = ref(null);

    const accessToken = useLocalStorage('accessToken', null);
    const refreshToken = useLocalStorage('refreshToken', null);
    const accessTokenExpiry = useLocalStorage('accessTokenExpiry', 0);

    const authenticated = computed(() => {
        return accessToken.value !== null;
    });

    const showMigrationHelp = useLocalStorage('showMigrationHelp', true);

    const showSearchModal = ref(false);
    const searchQuery = ref('');

    const sidebarIsOpen = useStorage('sidebarIsOpen', true);

    return {
        hydrated,
        hydrating,
        error,
        accessToken,
        refreshToken,
        accessTokenExpiry,
        authenticated,
        showMigrationHelp,
        showSearchModal,
        searchQuery,
        sidebarIsOpen,
    };
});
