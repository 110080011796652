import i18n from '@/lang/i18n.js';

export function translateAPIError(error, fallback = undefined) {
    const defaultMsg = i18n.global.t('error.something_went_wrong');

    let code = error;

    if (typeof error === 'object') {
        code = error?.response?.data?.errors?.[0]?.detail ?? error?.response?.data?.errors?.[0]?.title;
    }

    if (!error || !code) {
        return fallback ?? defaultMsg;
    }

    if (typeof code !== 'string') {
        return fallback ?? defaultMsg;
    }

    return code;
}
