<script setup>
import CFNavigationSidebar from '@/components/CFNavigationSidebar.vue';
import CFNotificationsSidebar from '@/components/CFNotificationsSidebar.vue';
import CFSearchModal from '@/components/Search/CFSearchModal.vue';
import CFNotificationBell from '@/components/CFNotificationBell.vue';
import CFDialog from '@/components/CFDialog.vue';
import CFTicketModal from '@/components/Ticket/CFTicketModal.vue';
import CFSupportCircle from '@/components/CFSupportCircle.vue';
import CFSearchCircle from '@/components/CFSearchCircle.vue';
import CFRequired2FABanner from '@/components/CFRequired2FABanner.vue';
import { useAccountStatus } from '@/composables/useAccountStatus.js';
import CFUnverifiedTeamBanner from '@/components/CFUnverifiedTeamBanner.vue';
import CFLockedTeamBanner from '@/components/CFLockedTeamBanner.vue';
import { useTeamStore } from '@/stores/team.js';

defineProps({
    toggle: {
        type: Boolean,
        default: false,
    },
});

const teamStore = useTeamStore();
const { shouldEnforceTFA, hasFullAccess } = useAccountStatus();
</script>

<template>
    <div
        class="relative isolate flex min-h-svh w-full"
        :class="{
            'has-banner':
                shouldEnforceTFA ||
                (teamStore.hasRequiredInformation && (!teamStore.isVerified || !teamStore.isUnlocked)),
        }"
    >
        <CFRequired2FABanner v-if="shouldEnforceTFA" />
        <CFUnverifiedTeamBanner v-else-if="teamStore.hasRequiredInformation && !teamStore.isVerified" />
        <CFLockedTeamBanner v-else-if="teamStore.hasRequiredInformation && !teamStore.isUnlocked" />

        <CFNavigationSidebar />

        <main
            id="main"
            class="main-content"
            :class="toggle ? 'with-sidebar' : 'no-sidebar'"
        >
            <slot />

            <div
                v-if="hasFullAccess"
                id="actionButtons"
                class="absolute right-8 top-8 flex items-center gap-4"
            >
                <CFSearchCircle />
                <CFSupportCircle />
                <CFNotificationBell />
            </div>
        </main>

        <CFSearchModal />

        <CFNotificationsSidebar />

        <CFDialog />
        <CFTicketModal />
    </div>
</template>
