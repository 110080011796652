<script setup>
import { computed } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    outline: {
        type: Boolean,
        default: false,
    },
    bgColor: {
        type: String,
        default: 'bg-white',
    },
    placeholder: {
        type: String,
        default: null,
    },
    modelValue: {
        type: null,
        default: null,
    },
    icon: {
        type: Boolean,
        default: true,
    },
});

const emits = defineEmits(['update:modelValue']);

const classObject = computed(() => {
    const object = props.bgColor ?? 'bg-white';

    if (props.outline) {
        return object + ' pl-0 border-b border-b-primary';
    }

    return object;
});
</script>

<template>
    <div>
        <label
            v-if="label"
            for="search"
            class="sr-only"
        >
            {{ label }}
        </label>

        <div class="relative rounded-none">
            <input
                id="search"
                type="text"
                name="search"
                :value="modelValue"
                :placeholder="placeholder || label"
                :class="classObject"
                class="block w-full border-0 pr-14 text-sm placeholder:text-neutrals-700 focus:border-b-black focus:ring-0"
                @input="emits('update:modelValue', $event.target.value)"
            />

            <button
                v-if="modelValue !== '' && modelValue !== null"
                type="button"
                class="absolute inset-y-0 -top-0.5 flex cursor-pointer items-center"
                :class="icon ? 'right-8' : 'right-0'"
                @click.prevent="emits('update:modelValue', '')"
            >
                <XMarkIcon class="h-5 w-5" />
            </button>

            <div
                v-if="icon"
                class="pointer-events-none absolute inset-y-0 -top-0.5 right-3 flex items-center"
            >
                <svg
                    viewBox="-0.75 -0.75 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="16"
                >
                    <path
                        d="M1.38 12.5221875a8.496562500000001 8.496562500000001 0 1 0 15.639375 -6.646875 8.496562500000001 8.496562500000001 0 1 0 -15.639375 6.646875Z"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    ></path>
                    <path
                        d="m15.2071875 15.206249999999999 6.5896875 6.590625"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    ></path>
                </svg>
            </div>
        </div>
    </div>
</template>
