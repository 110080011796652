export default [
    {
        name: 'English',
        locale: 'en',
    },
    {
        name: 'Nederlands',
        locale: 'nl',
    },
];
