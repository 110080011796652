import { helpers } from '@vuelidate/validators';

export function formatPeriod(period) {
    if (period === '1Y') {
        return 'general.year';
    } else if (period === '90D') {
        return 'general.90_days';
    }

    return 'general.month';
}

export function generatePassword() {
    const length = 16;
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const symbols = '~!@-#$';
    const allCharacters = lowercase + uppercase + numbers + symbols;

    const getRandomChar = (characters) => characters[Math.floor(Math.random() * characters.length)];

    let password = [getRandomChar(lowercase), getRandomChar(uppercase), getRandomChar(numbers), getRandomChar(symbols)];

    for (let i = password.length; i < length; i++) {
        password.push(getRandomChar(allCharacters));
    }

    // Shuffle the password to ensure random order
    return password.sort(() => 0.5 - Math.random()).join('');
}

export const phoneNumberValidator = helpers.regex(/^\+?[\d\s()-]{6,20}$/);

export const getAssetUrl = (path) => {
    return new URL(path, import.meta.url).href;
};

export const extractInitials = (value) => {
    if (!value || typeof value !== 'string') {
        return '';
    }

    return value
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase())
        .join('');
};

export const currencyToSymbol = (currency) => {
    switch (currency) {
        case 'usd':
            return '$';
        case 'eur':
            return '€';
        case 'gbp':
            return '£';
        default:
            return currency;
    }
};

export const getFormattedPrice = (price, currency = null) => {
    if (!currency) {
        return price.formatted;
    }

    return currencyToSymbol(currency) + ' ' + price.formatted;
};
