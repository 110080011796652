export default {
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
    nl: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
};
