import { defineStore } from 'pinia';
import api from '@/plugins/axios.js';
import { reactive, ref } from 'vue';

export const useSSLStore = defineStore('ssl', () => {
    const state = reactive({
        page: 1,
        sort: 'status',
        filters: {
            status: [],
            type: [],
        },
    });
    const search = ref('');

    const setPage = (newPage) => {
        state.page = newPage;
    };

    const setSort = (newSort) => {
        state.sort = newSort;
    };

    const setFilters = (newFilters) => {
        Object.keys(newFilters).forEach((key) => {
            if (newFilters[key] === undefined) {
                return;
            }

            state[key] = newFilters[key];
        });
        setPage(1);
    };

    const setSearch = (newSearch) => {
        search.value = newSearch;
        setPage(1);
    };

    const getSSLCertificatesWithFilters = async () => {
        const params = {
            page: state.page,
            filter: {},
        };

        if (search.value) {
            params['filter']['search'] = search.value;
        }

        Object.keys(state).forEach((key) => {
            if (key === 'page' || key === 'sort') {
                return;
            }

            if (Array.isArray(state[key]) && state[key].length) {
                params['filter'][key] = state[key].join('|');
            } else if (state[key]) {
                params['filter'][key] = state[key];
            }
        });

        return await getSSLCertificates(params);
    };

    async function getSSLCertificates(params = {}) {
        const { data } = await api.get(`/general/ssl-certificates`, { params });

        data.data.forEach((certificate) => {
            certificate.product = data.included.find((p) => p.id === certificate.relationships.product.data.id);
        });

        return data;
    }

    function getSSLCertificate(certificate) {
        return api.get(`/general/ssl-certificates/${certificate}`);
    }

    function getSSLCertificateStatus(certificate) {
        return api.get(`/general/ssl-certificates/${certificate}/request-status`);
    }

    function downloadSSLCertificate(certificate) {
        return api.get(`/general/ssl-certificates/${certificate}/download`, {
            responseType: 'blob',
        });
    }

    function renewSSLCertificate(certificate) {
        return api.post(`/general/ssl-certificates/${certificate}/renew`);
    }

    function revokeSSLCertificate(certificate) {
        return api.get(`/general/ssl-certificates/${certificate}/revoke`);
    }

    function resendApprovalEmailSSLCertificate(certificate) {
        return api.get(`/general/ssl-certificates/${certificate}/resend-approval-email`);
    }

    function getApprovalListSSLCertificate(certificate) {
        return api.get(`/general/ssl-certificates/${certificate}/approval-list`);
    }

    return {
        state,
        search,
        setPage,
        setSort,
        setFilters,
        setSearch,
        getSSLCertificatesWithFilters,
        getSSLCertificates,
        getSSLCertificate,
        getSSLCertificateStatus,
        downloadSSLCertificate,
        renewSSLCertificate,
        revokeSSLCertificate,
        resendApprovalEmailSSLCertificate,
        getApprovalListSSLCertificate,
    };
});
