import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';
import numberFormats from '@/lang/number-formats.js';

export const i18n = createI18n({
    messages,
    numberFormats,
    legacy: false,
    missingWarn: true,
    fallbackWarn: true,
    globalInjection: true,
    locale: navigator.language.split('-')[0],
    fallbackLocale: 'nl',
});

export default i18n;
