import { defineStore } from 'pinia';
import { ref } from 'vue';
import api from '@/plugins/axios.js';

export const usePermissionsStore = defineStore('permissions', () => {
    const permissions = ref([]);

    const hydrate = async () => {
        try {
            const response = await api.get(`/general/permissions`);

            if (response.status !== 200) {
                return;
            }

            permissions.value = response.data.data.map((k) => k.attributes.name);
        } catch (e) {
            // No need to tell user, they can continue and use default permissions.
        }
    };

    const dehydrate = () => {
        permissions.value = [];
    };

    const hasPermission = (permission) => {
        return permissions.value.includes(permission);
    };

    const hasAnyPermission = (keys) => {
        return keys.some((permission) => permissions.value.includes(permission));
    };

    const hasAllPermissions = (keys) => {
        return keys.every((permission) => permissions.value.includes(permission));
    };

    return {
        hydrate,
        dehydrate,
        hasPermission,
        hasAnyPermission,
        hasAllPermissions,
    };
});
