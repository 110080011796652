import api, { resumeQueue } from '@/plugins/axios';
import { dehydrate, hydrate } from '@/services/hydrate';
import { useAppStore } from '@/stores/app.js';
import { isNavigationFailure } from 'vue-router';
import router from '@/router';
import { useQueryClient } from '@tanstack/vue-query';

/**
 * @param {object} credentials
 */
export async function login(credentials) {
    return await api.post('/general/oauth/token', credentials, {
        login: true,
    });
}

/**
 * @param {object} credentials
 */
export async function register(credentials) {
    return await api.post('/general/team', credentials);
}

/**
 * @param {object} credentials
 */
export async function confirmOTP(credentials) {
    return await api.post('/general/oauth/confirm', credentials);
}

let refreshTimeout;

/**
 * @param {object} data
 */
export async function authenticate(data) {
    const appStore = useAppStore();

    // Refresh the token 1 minute before the access token expires. This means the user will stay
    // logged in without any noticeable hiccups or delays
    refreshTimeout = setTimeout(() => refresh(), (data.expires_in - 60) * 1000);

    const accessToken = data.access_token;
    const tokenType = data.token_type;

    // Add the header to the API handler for every request
    api.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`;

    appStore.accessToken = accessToken;
    appStore.refreshToken = data.refresh_token;
    appStore.accessTokenExpiry = Date.now() + data.expires_in * 1000;

    await hydrate();
}

export const LogoutReason = {
    SESSION_EXPIRED: 'session_expired',
    SIGN_OUT: 'sign_out',
    AUTO: 'auto',
};

let isRefreshing = false;
let firstRefresh = true;

export async function refresh({ navigate } = { navigate: true }) {
    const appStore = useAppStore();

    // Allow refresh during initial page load
    if (firstRefresh) {
        firstRefresh = false;
    } else if (!appStore.authenticated) {
        return; // Skip if not logged in
    }

    // Prevent concurrent refreshes
    if (isRefreshing) {
        return;
    }

    // Skip refresh if no refresh token is available
    if (!appStore.refreshToken) {
        return;
    }

    // Skip refresh if access token is still fresh
    if (appStore.accessTokenExpiry && Date.now() < appStore.accessTokenExpiry - 10000) {
        return;
    }

    isRefreshing = true;

    try {
        const data = {
            refresh_token: appStore.refreshToken,
            grant_type: 'refresh_token',
        };

        const response = await api.post('/general/oauth/token', data);

        // Refresh the token 10 seconds before the access token expires. This means the user will stay
        // logged in without any notable hiccups or delays
        clearTimeout(refreshTimeout);

        const accessToken = response.data.access_token;
        const tokenType = response.data.token_type;

        // Add the header to the API handler for every request
        api.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`;

        if (response.data.refresh_token) {
            appStore.refreshToken = response.data.refresh_token;
        }

        appStore.accessToken = accessToken;
        appStore.accessTokenExpiry = Date.now() + response.data.expires_in * 1000;

        return `${tokenType} ${accessToken}`;
    } catch (error) {
        await logout({ navigate, reason: LogoutReason.SESSION_EXPIRED });
    } finally {
        isRefreshing = false;
        resumeQueue();
    }
}

export async function logout(optionsRaw) {
    const appStore = useAppStore();
    const queryClient = useQueryClient();

    clearTimeout(refreshTimeout);

    const defaultOptions = {
        navigate: true,
        reason: LogoutReason.SIGN_OUT,
    };

    const options = { ...defaultOptions, ...optionsRaw };

    // Only if the user manually signed out should we delete the session by hitting the logout endpoint
    if (options.reason === LogoutReason.SIGN_OUT) {
        try {
            await api.post('/general/logout');
        } catch {
            // User already signed out, errors don't matter
        }
    }

    appStore.accessToken = null;
    appStore.refreshToken = null;
    appStore.accessTokenExpiry = 0;

    // Clear all caches
    await queryClient.invalidateQueries();

    if (options.navigate === true) {
        const failure = await router.replace({ name: 'login' });

        // Force redirect to login page if the navigation was aborted
        if (isNavigationFailure(failure)) {
            window.location.href = '/login';
        }
    }

    /*
     * Important to clear all the stores after logout, otherwise the
     * application will complain about missing user data.
     */
    await dehydrate();
}

export default {
    login,
    register,
    confirmOTP,
    authenticate,
    refresh,
    logout,
};
