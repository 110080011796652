<script setup>
import { PlusIcon } from '@heroicons/vue/24/solid/index.js';
import { useRouter } from 'vue-router';
import CFCircleSpinner from '@/components/CFCircleSpinner.vue';

defineProps({
    name: {
        type: String,
        required: true,
    },
    amount: {
        type: Number,
        required: true,
    },
    href: {
        type: String,
        required: true,
    },
    order: {
        type: String,
        required: true,
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

const router = useRouter();
</script>

<template>
    <div
        class="btn-product"
        type="button"
        @click="router.push({ name: href })"
    >
        <h2
            v-if="!loading"
            class="text-4xl font-bold leading-15 lg:text-5xl 2xl:text-6xl"
        >
            {{ amount }}
        </h2>
        <div
            v-else
            class="flex h-[48px] items-center justify-center"
        >
            <CFCircleSpinner />
        </div>
        <p class="text-lg lg:text-xl 2xl:text-2xl">{{ name }}</p>

        <div class="view">
            <span class="mt-0.5 block text-sm leading-none">{{ $t('dashboard.view') }}</span>
            <span class="block">
                <svg
                    height="18"
                    viewBox="-0.5 -0.5 24 24"
                    width="18"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.9583333333333334 11.498083333333334a10.541666666666668 10.541666666666668 0 1 0 21.083333333333336 0 10.541666666666668 10.541666666666668 0 1 0 -21.083333333333336 0Z"
                        class="arrow-line"
                        stroke="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                    <path
                        d="m5.75 11.498083333333334 12.458333333333334 0"
                        fill="none"
                        class="arrow"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                    <path
                        d="m13.895833333333334 7.185583333333334 4.3125 4.3125 -4.3125 4.3125"
                        fill="none"
                        class="arrow"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                </svg>
            </span>
        </div>

        <button
            type="button"
            class="btn-pink-gradient btn-scale absolute right-4 top-4 flex h-8 w-8 items-center justify-center rounded-full before:rounded-full"
            @click.stop="router.push({ name: order })"
        >
            <PlusIcon class="h-5 w-5 fill-current text-white" />
        </button>
    </div>
</template>

<style scoped>
.btn-product {
    @apply relative flex cursor-pointer flex-col items-center justify-center overflow-hidden p-8 transition-all duration-300 ease-linear before:absolute before:inset-0 before:-z-[1] before:h-full before:w-full before:translate-y-full before:opacity-0 before:transition-all before:duration-300 before:ease-linear after:absolute after:inset-0 after:-z-[2] after:h-full after:w-full after:bg-neutrals-300 hover:text-secondary hover:before:translate-y-0 hover:before:bg-primary hover:before:opacity-100 lg:flex-1;

    > *:not(.btn-pink-gradient) {
        @apply relative z-10;
    }

    .view {
        @apply relative mt-5 flex items-center gap-x-2 transition-transform duration-300 ease-in-out;

        svg {
            @apply -rotate-45 text-primary transition-all duration-100 ease-in-out;

            .arrow {
                @apply stroke-secondary;

                &-line {
                    @apply fill-primary;
                }
            }
        }
    }

    &:hover {
        .view {
            @apply translate-x-1;
        }

        svg {
            @apply rotate-0 text-secondary;

            .arrow {
                @apply stroke-primary;

                &-line {
                    @apply fill-secondary;
                }
            }
        }
    }
}
</style>
