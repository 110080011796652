import { defineStore } from 'pinia';

export const useLoader = defineStore('loader', {
    state: () => ({
        loading: false,
        requestsPending: 0,
    }),
    actions: {
        show() {
            this.loading = true;
        },
        hide() {
            this.loading = false;
        },
        pending() {
            if (this.requestsPending === 0) {
                this.loading = true;
            }

            this.requestsPending++;
        },
        /**
         * @param {Promise} fn
         * @return Promise
         */
        async pendingTask(fn) {
            this.pending();

            await fn;

            this.done();
        },
        /**
         * @param {Promise[]} arr
         * @return Promise
         */
        async pendingTasks(arr) {
            this.pending();

            await Promise.all(arr);

            this.done();
        },
        done() {
            if (this.requestsPending >= 1) {
                this.requestsPending--;
            }

            if (this.requestsPending <= 0) {
                this.loading = false;
            }
        },
    },
});
