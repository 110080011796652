import { useToast } from 'vue-toastification';
import { computed, ref } from 'vue';

export function useValidationErrors() {
    const errors = ref([]);

    const setErrors = (newErrors) => {
        if (!Array.isArray(newErrors)) {
            return;
        }

        errors.value = newErrors;
    };

    const hasErrors = computed(() => {
        return errors.value.length > 0;
    });

    const all = computed(() => {
        return errors.value;
    });

    const first = computed(() => {
        return errors.value[0]?.detail;
    });

    const hasData = (field) => {
        return (
            errors.value.find(
                (error) => error.source.pointer.replace(/^\/+/, '') === field && error.type === 'data'
            ) !== undefined
        );
    };

    const hasType = (field) => {
        return (
            errors.value.find(
                (error) => error.source.pointer.replace(/^\/+/, '') === field && error.type === 'type'
            ) !== undefined
        );
    };

    const has = (field) => {
        field = field.replace(/\./g, '/');

        return errors.value.find((error) => error.source.pointer.replace(/^\/+/, '') === field) !== undefined;
    };

    const get = (field, key = 'detail') => {
        field = field.replace(/\./g, '/');

        let found = errors.value.find((error) => error.source.pointer.replace(/^\/+/, '') === field);

        return found ? (found[key] ?? null) : null;
    };

    const startsWith = (key) => {
        key = key.replace(/\./g, '/');

        return errors.value.filter((k) => k.source.pointer.replace(/^\/+/, '').startsWith(key + '/'));
    };

    const triggerToast = () => {
        let error = errors.value.filter((error) => error.type === 'type').map((error) => error.detail);

        if (error.length > 0) {
            useToast().error(error.join('\n'), {
                timeout: 2000,
            });
        }
    };

    const reset = () => {
        errors.value = [];
    };

    return {
        errors,
        setErrors,
        hasErrors,
        all,
        first,
        has,
        hasData,
        hasType,
        get,
        startsWith,
        triggerToast,
        reset,
    };
}
