<script setup>
import CFButton from '@/components/CFButton.vue';
import { logout, LogoutReason } from '@/services/auth.js';

const goToLoginPage = async () => {
    await logout({
        reason: LogoutReason.AUTO,
    });

    window.location.reload();
};
</script>

<template>
    <div class="flex min-h-screen w-full flex-col items-center justify-center gap-6 bg-black text-center text-white">
        <img
            class="h-20 invert"
            src="/Cyberfusion_Logo.svg"
            alt="Cyberfusion logo"
        />

        <h1 class="text-2xl font-bold">{{ $t('error.unexpected_title') }}</h1>

        <p>{{ $t('error.unexpected_description') }}</p>

        <CFButton
            type="button"
            color="secondary"
            outline
            @click="goToLoginPage"
        >
            {{ $t('button.back_to_login') }}
        </CFButton>
    </div>
</template>
